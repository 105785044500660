// Constants
import { TRIAL_DAYS } from '@/constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
import PlansSendEmail from '@/components/elements/plans/PlansSendEmail'
import PlansTrialVersion from '@/components/elements/plans/PlansTrialVersion'
// Vuex
import { mapGetters } from 'vuex'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Utils
import { isNil } from 'lodash'
import { PACKAGES } from '@/constants'

export default {
  name: 'PlansList',
  components: {
    CardContainer,
    VuetifyToolBar
  },
  mixins: [uiMixin],
  data() {
    return {
      plans: [
        {
          id: 'pro',
          title: 'Pro',
          price: '29,90 €/mes',
          description: 'Todo lo necesario para empezar a vender online.',
          items: [
            'Sin publicidad en la carta',
            'Contacto y Ubicación',
            'Personalización avanzada',
            'Galería de imágenes',
            'Destacados y fuera de carta',
            'Pedidos Online',
            'Acceso a datos de clientes',
            'Gestión avanzada de carta',
            '2% comisión por pedido'
          ],
          checkout: true
        },
        {
          id: 'advance',
          title: 'Advance',
          price: '89,90 €/mes',
          description:
            ' Lleva tu establecimiento a otro nivel, con el set completo de nuestras herramientas.',
          items: [
            'Todo lo del plan PRO',
            '1% comisión por pedido',
            'Dominio personalizable',
            'PWA personalizable'
          ],
          checkout: false
        }
      ]
    }
  },
  computed: {
    ...mapGetters('company', ['trialPlan']),
    ...mapGetters('place', ['subscribedPackages']),
    /**
     * Preparamos las opciones de los planes
     * para mostrar correctamente botones y demás
     *
     * @return {array}
     */
    parsedPlans() {
      // Tenemos posibilidad de TRIAL
      const hasTrial = isNil(this.trialPlan) && this.subscribedPackages.length === 0
      // Estamos usando el TRIAL actualmente
      const inTrial = !isNil(this.trialPlan) && this.trialPlan.daysLeft > -1
      return this.plans.map((plan) => {
        // El plan puede ser adquirido por proceso "checkout"
        const hasCheckout = plan.checkout
        // Ya tenemos una suscripción sobre dicho plan
        const hasSubscription = Boolean(
          this.subscribedPackages.find((suscribed) => {
            return suscribed.packageId === plan.id
          })
        )
        // Etiqueta del botón por defecto
        let buttonLabel = 'Contratar Plan'

        if (!hasCheckout) {
          buttonLabel = 'Contactar con ventas'
        } else if (hasTrial) {
          buttonLabel = `Prueba ${TRIAL_DAYS} días ¡gratis!`
        } else if (!inTrial && hasSubscription) {
          buttonLabel = 'Actualizar plan'
        }

        return {
          ...plan,
          buttonLabel,
          buttonColor: !plan.checkout ? 'primary' : 'secondary'
        }
      })
    }
  },
  methods: {
    /**
     * Show the request form to "advance" plan
     *
     * @param {object} plan - datos del plan
     */
    handleClickAction(plan) {
      if (plan.id === PACKAGES.advance.value) {
        // Modal de solicitud
        this.modifyAppDialog({
          title: 'Enviar solicitud',
          contentComponent: PlansSendEmail,
          hideActionButtons: true,
          visible: true
        })
      } else if (isNil(this.trialPlan) && this.subscribedPackages.length === 0) {
        // Modal de trial
        this.modifyAppDialog({
          hideTitle: true,
          contentComponent: PlansTrialVersion,
          contentComponentProps: {
            id: plan.id
          },
          hideActionButtons: true,
          visible: true
        })
      } else if (!isNil(this.trialPlan) || this.subscribedPackages.length > 0) {
        // Redirigimos a checkout
        this.routerPushTo({
          path: `/checkout/${plan.id}`
        })
      }
    }
  }
}
